/*include fonts*/
@import "font.css";

/*include global css*/
@import "global/global.css";
@import "global/header.css";
@import "global/footer.css";

@import "components/order_form.css";
@import "components/left_menu.css";
@import "components/magnific_popup.css";
@import "effects/_animate.css";

/*include all styles*/
@import "modules/_index.css";

