.left-menu {
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
  .block {
    background: #F2F2F2;
    margin: 0 0 15px;
    padding: 30px;
  }

  p {
    font-family: "Casper", sans-serif;
    font-size: 20px;
    margin: 0 0 15px;
    line-height: 1.2;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      line-height: 1;
      a {
        text-decoration: none;
        color: #0057CD;
        display: block;
        padding: 7px 0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}