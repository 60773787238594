.order-form {
  padding: 20px;
  background: #F2F2F2;
  margin: 0 30px;
  width: 100%;
  @media (min-width: 576px) {
    padding: 40px;
  }
  .head {
    margin: 0 0 25px;
  }
  .heading {
    line-height: 1;
    margin: 0 0 10px;
    @media (min-width: 992px) {
      margin: 0;
    }
  }
  .description {
    font-size: 16px;
  }

  .controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0 0;
    align-items: center;
    @media (min-width: 992px) {
      flex-direction: row;
      align-items: flex-start;
    }
    .input-file-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0 0 15px;
      @media (min-width: 992px) {
        margin: 0;
        align-items: flex-start;
        text-align: left;
        .form-group:first-child {
          margin-right: 10px;
        }
      }
    }
    .agree-and-submit {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 992px) {
        align-items: flex-end;
      }
      .checkbox {
        margin: 0 5px;
        text-align: center;
        @media (min-width: 922px) {
          margin: 0 10px;
          text-align: left;
        }
      }
    }
  }
  .star {
    color: #0057cd;
  }
}


/*
.order-form {
  padding: 30px;
  background: #F2F2F2;
  width: 100%;
  .heading {
    margin: 0 0 30px;
    line-height: 1;
  }
  .description {
    font-size: 16px;
  }

  .controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0 0;
    align-items: center;
    @media (min-width: 992px) {
      flex-direction: row;
      align-items: flex-start;
    }
    .input-file-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0 0 15px;
      @media (min-width: 992px) {
        margin: 0;
        align-items: flex-start;
        text-align: left;
        .form-group:first-child {
          margin-right: 10px;
        }
      }
      @media (min-width: 1200px) {
        flex-direction: row;
        align-items: flex-end;
      }
    }
    .agree-and-submit {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 992px) {
        flex-direction: column-reverse;
        align-items: flex-end;
      }
      @media (min-width: 1200px) {
        flex-direction: row;
      }
      .checkbox {
        margin: 0 5px;
        text-align: center;
        @media (min-width: 992px) {
          margin: 0 10px;
          text-align: left;
        }
      }
    }
  }
  .star {
    color: #0057cd;
  }
}
*/