.page-footer {
  padding: 20px 0;
  color: #b2b2b2;
  background: #10121c;
  text-align: center;
  @media (min-width: 992px) {
    text-align: left;
  }
  a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: color .3s;
    transition: color .3s;

  }
  &:hover {
    color: #bdbdbd;
  }

  .logo {
    display: block;
    margin: 0 0 10px;
    font-size: 40px;
    line-height: 1;
  }

  .copyright {
    font-size: 12px;
    margin: 0 0 25px;
    @media (min-width: 992px) {
      margin: 0;
    }
  }

  .menu {
    margin: 0 0 25px;
    @media (min-width: 992px) {
      margin: 0;
    }
    li {
      margin-bottom: 8px;
      font-size: 18px;
    }
  }

  .contact-details li {
    margin: 0 0 12px;
    font-size: 18px;
  }
}