.header {
  background: #ffffff;
  padding: 15px 0 10px;
  &-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .menu-close-btn {
    background: url("../img/close_btn.png") 0 0 no-repeat;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    @media (min-width: 992px) {
      display: none;
    }
  }

  .main-menu {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      flex-direction: row;
      margin: 0;
    }
    li {
      font-size: 14px;
      padding: 0;
      @media (min-width: 992px) {
        padding: 0 10px;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    a {
      text-decoration: none;
      color: #000000;
      display: inline-block;
      position: relative;
      line-height: 1.4;
      padding: 8px 0;
      @media (min-width: 992px) {
        display: block;
      }
      &:after {
        display: block;
        position: absolute;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #5a1a2d;
        content: "";
        transition: width 0.3s ease-out;
      }
      &:hover:after,
      &:focus:after {
        width: 100%;
      }
    }
  }

  .header-contacts {
    list-style: none;
    margin: 0 0 5px;
    padding: 0;
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      flex-direction: row;
    }
    li {
      margin: 0 0 15px;
      @media (min-width: 992px) {
        margin: 0 0 0 15px;
      }
    }
    a {
      color: #29292a;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      &:hover {
        color: #5a1a2d;
        text-decoration: none;
        svg {
          path {
            fill: #5a1a2d;
          }
        }
      }
    }
    .icon {
      margin: 0 6px 0 0;
    }
    svg {
      width: 20px;
      vertical-align: middle;
    }
  }
}

.menu-btn {
  width: 35px;
  height: 40px;
  position: relative;
  cursor: pointer;
  z-index: 50;
  display: block;
  @media (min-width: 420px){
    width: 50px;
  }
  @media (min-width: 992px) {
    display: none;
  }
  &:before, &:after {
    content: "";
  }
  span, &:before, &:after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: #5a1a2d;
    transition: all .2s;
    pointer-events: none;
  }
  &:before {
    top: 7px;
  }
  &:after {
    top: 29px;
  }
  span {
    top: 18px;
  }
}

.header-menu-wrapper {
  display: flex;
  position: fixed;
  right: 0;
  background: #fff;
  z-index: 100;
  width: 250px;
  top: 0;
  height: 100%;
  flex-direction: column;
  padding: 50px 25px;
  box-shadow: 1px 1px 20px rgba(0,0,0,0.3);
  transform: translateX(110%);
  transition: all .3s;
  @media (min-width: 992px) {
    position: relative;
    flex-direction: column-reverse;
    align-items: flex-end;
    height: auto;
    width: auto;
    padding: 0;
    box-shadow: none;
    transform: translateX(0);
  }
}

.mobile-menu-opened {
  .header-menu-wrapper {
    transform: translateX(0);
  }
}