@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

body, input, select, button, textarea {
  font-family: 'ClearSans', Helvetica, Arial, sans-serif;
}

h1, .h1, h2, .h2, .white-popup .title, .process .card, .features .card.mod-1 .title, .examples .card .card-text, .services .card .title, .reviews .card .title, .equip .card .title {
  font-family: 'TT Squares', Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.color-default {
  color: #282b34 !important;
}

.color-primary {
  color: #0096ff !important;
}

.color-white {
  color: #fff !important;
}

.bg-default {
  background: #282b34;
}

.bg-primary {
  background: #0096ff;
}

html, body {
  height: 100%;
  font-size: 14px;
  @media (min-width: 576px){
    font-size: 18px;
  }
}

body {
  color: #282b34;
  line-height: 1.2;
  background: #fff;
}

a {
  text-decoration: underline;
  outline: none !important
}

.logo, h1, .h1, h2, .h2 {
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.4rem;
}

h3, .h3 {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
}

h4, .h4 {
  margin-top: 0;
  margin-bottom: 10px;
}

.page-wrapper {
  min-width: 320px;
  min-height: 100%;
  position: relative;
  &.index {
    .page-body {
      padding-top: 0;
    }
    .case {
      margin: 0 0 40px;
    }
  }
}

.page-title {
  margin: 0 0 30px;
}

.case {
  padding: 0;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.p-t-0 {
  padding-top: 0
}

.p-t-20 {
  padding-top: 20px
}

.p-t-30 {
  padding-top: 30px
}

.p-t-40 {
  padding-top: 40px
}

.p-t-60 {
  padding-top: 60px
}

.imgLiquidFill {
  background-color: #f0f0f0
}

.services .card .card-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2
}

.topbar .contact-details, .navbar .menu, .mobile-nav .menu, .mobile-nav .contact-details, .page-footer .menu, .page-footer .contact-details, .contact .contact-details, .equip .card .text ul {
  padding: 0;
  margin: 0;
  list-style: none
}

input, select, button, textarea {
  color: #888;
  font-size: 16px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important
}

.btn {
  min-width: 200px;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background .3s;
  padding: 0.5rem 0.75rem;
}

.btn-default {
  color: #fff !important;
  background: #0057cd;
  &:hover {
    background: #004cb4;
  }
}

.btn-default:active, .btn-default:focus {
  background: #adadad !important;
}

.btn-outline-primary {
  border: 1px solid #0057CD;
  color: #0057CD !important;
  background: transparent;
  &:hover {
    color: #ffffff !important;
  }
}

.btn-primary {
  background-color: #0057cd;
  border-color: #0057cd;
  &:hover, &:active, &:focus {
    background: #004cb4;
  }
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  padding: 0;
  font-size: 15px;
  font-weight: 400
}

.form-control {
  padding: 12px;
  font-size: 16px;
  border-color: #ccc !important;
  background: #ffffff;
  border-radius: 0
}

.form-control::-webkit-input-placeholder {
  color: #6f7380
}

.form-control:-moz-placeholder {
  color: #6f7380
}

.form-control::-moz-placeholder {
  color: #6f7380
}

.form-control:-ms-input-placeholder {
  color: #6f7380
}

.form-control:focus {
  border-color: #888 !important
}

input.form-control {
  height: 52px
}

.file-input-wrapper {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1
}

.file-input-wrapper input[type=file], .file-input-wrapper input[type=file]:focus, .file-input-wrapper input[type=file]:hover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0)
}

.file-input-name {
  display: block;
  margin-bottom: 7px;
  font-size: 16px;
}

.checkbox {
  margin: 0;
  padding: 5px 0
}

.checkbox label:after, .radio label:after {
  content: '';
  display: table;
  clear: both
}

.checkbox .cr, .radio .cr {
  position: relative;
  display: inline-block;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: .5em;
  border: 1px solid #ccc
}

.radio .cr {
  -webkit-border-radius: 50%;
  border-radius: 50%
}

.checkbox .cr .cr-icon, .radio .cr .cr-icon {
  position: absolute;
  top: 50%;
  left: 20%;
  color: #0057cd;
  font-size: .8em;
  line-height: 0;
}

.radio .cr .cr-icon {
  margin-left: 0.04em;
}

.checkbox label input[type=checkbox], .radio label input[type=radio] {
  display: none;
}

.checkbox label input[type=checkbox] + .cr > .cr-icon, .radio label input[type=radio] + .cr > .cr-icon {
  -webkit-transform: scale(3) rotateZ(-20deg);
  -ms-transform: scale(3) rotate(-20deg);
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

.checkbox label input[type=checkbox]:checked + .cr > .cr-icon, .radio label input[type=radio]:checked + .cr > .cr-icon {
  -webkit-transform: scale(1) rotateZ(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox label input[type=checkbox]:disabled + .cr, .radio label input[type=radio]:disabled + .cr {
  opacity: .5;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 10;
}

.page-header {
  padding: 0;
  margin: 0;
  border-bottom: 0;
}

.page-header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);;

  .topbar {
    position: relative;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 992px) {
      border-bottom: 2px solid #ffffff;
    }
    &-left {
      display: flex;
      align-items: center;
    }
  }

  .logo {
    color: #131313;
    text-decoration: none;
    transition: opacity .3s;
    font-family: 'Casper', Helvetica, Arial, sans-serif;
    @media (min-width: 992px) {
      margin: 0 50px 0 0;
    }
    &:hover {
      opacity: 0.5;
    }
    span {
      display: block;
      &.site-name {
        font-size: 42px;
        line-height: 1;
      }
      &.site-slogan {
        font-family: 'ClearSans', Helvetica, Arial, sans-serif;
        font-size: 11px;
        line-height: 1;
        padding: 0 0 0 2px;
        letter-spacing: -0.035em;
      }
    }
  }

  .btn {
    margin: 0 30px 0 0;
    display: none;
    @media (min-width: 992px) {
      display: block;
    }
  }

  .contact-details {
    display: none;
    @media (min-width: 992px) {
      display: block;
    }
    li {
      font-size: 18px;
      padding: 0 0 0 25px;
      &.time {
        background: url("../img/icon_time_black.svg") no-repeat 2px 50%;
      }
      &.email {
        background: url("../img/icon_mail_black.svg") no-repeat 0 50%;
      }
    }
    a {
      transition: opacity .3s;
      color: #000000;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .phone {
    padding: 0 0 0 30px;
    background: url("../img/icon_phone_black.svg") no-repeat 0 20%;
    display: none;
    @media (min-width: 576px) {
      display: block;
    }
    @media (min-width: 992px) {
      margin: 0;
    }
  }

  .phone-num, .order-call-link {
    display: block;
    text-decoration: none;
    color: inherit;
    transition: opacity .3s;
    &:hover {
      opacity: 0.5;
    }
  }

  .phone-num {
    font-size: 24px;
    font-weight: 600;
  }

  .order-call-link {
    font-size: 18px;
    border-bottom: 1px solid #000000;
  }

  .menu {
    padding: 15px 0;
    margin: 0 -30px;
    display: none;
    list-style: none;
    justify-content: space-between;
    background: #F2F2F2;
    @media (min-width: 992px) {
      display: flex;
      > li {
        flex-grow: 1;
        position: relative;
        text-align: center;
        line-height: 1;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.dropdown {
          &:after {
            content: "";
            right: 16px;
            background: url(../img/icon_arrow_down.svg);
            width: 12px;
            height: 7px;
            top: 6px;
          }
          & > a {
            padding-right: 25px;
          }
        }
        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background: #4f4f4f;
        }
        &:last-child {
          &:before {
            display: none;
          }
        }
        > a {
          color: inherit;
          text-decoration: none;
          display: block;
          padding: 0 10px;
          transition: color .3s;
        }
        &:hover {
          .sub {
            display: block;
          }
        }
      }

      .sub {
        list-style: none;
        position: absolute;
        left: 0;
        padding: 15px;
        background: #F2F2F2;
        text-align: left;
        min-width: 250px;
        top: 100%;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        display: none;
        li {
          font-size: 16px;
          a {
            color: inherit;
            text-decoration: none;
            display: block;
            padding: 6px 0;
            transition: color 0.2s;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

    }

  }

  .mobile-toggle {
    top: 21px;
    right: 5px;
    width: 30px;
    cursor: pointer;
    @media (min-width: 576px) {
      top: 26px;
    } @media (min-width: 992px) {
    display: none;
  }
    .icon-bar {
      display: block;
      height: 4px;
      margin-top: 5px;
      background: #0057cd;
      -webkit-transition: background .3s;
      transition: background .3s
    }
  }

  &.black {
    background: rgba(0, 0, 0, 0.7);
    a {
      color: #ffffff;
    }
    .logo {
      color: #fff;
      &:hover {
        color: #bdbdbd;
      }
    }
    .contact-details {
      li {
        color: #fff;
        &.time {
          background: url("../img/icon_time_white.svg") no-repeat 2px 50%;
        }
        &.email {
          background: url("../img/icon_mail_white.svg") no-repeat 0 50%;
        }
      }
      a {
        &:hover {
          color: #bdbdbd;
        }
      }
    }

    .phone {
      padding: 0 0 0 35px;
      background: url("../img/icon_phone_white.svg") no-repeat 0 20%;
    }

    .phone-num, .order-call-link {
      color: #ffffff;
      &:hover {
        color: #bdbdbd;
      }
    }

    .order-call-link {
      border-bottom: 1px solid #ffffff;
    }

    .menu {
      justify-content: center;
      background: transparent;
      > li {
        flex-grow: 0;
        &:after {
          display: none;
        }
      }
      a {
        color: #ffffff;
        padding: 0 20px;
        &:hover {
          color: #bdbdbd;
        }
      }
    }
    .mobile-toggle {
      .icon-bar {
        background: #fff;
      }
      &:hover .icon-bar {
        background: #bdbdbd;
      }
    }
  }
}

.time {
  font-size: 18px;
}

.addr {
  font-size: 15px;
  margin-top: 10px;
}

.tel {
  font-size: 18px;
  margin-top: 10px;
}

.navbar {
  margin: 0
}

@media (max-width: 991px) {
  .navbar {
    display: none
  }
}

.navbar .menu {
  padding-left: 18px;
  border-top: 2px solid transparent;
  background: #f2f2f2
}

.navbar .menu li {
  margin: 0 18px;
  float: left;
  font-size: 19px;
  line-height: 46px
}

.navbar .menu li.cb {
  margin: -2px 0 0;
  float: right
}

.navbar .menu li.cb a {
  display: inline-block;
  padding: 0 20px;
  color: #fff;
  font-size: 18px;
  line-height: 50px;
  background: #0057cd;
  -webkit-transition: background .3s;
  transition: background .3s
}

@media (min-width: 1200px) {
  .navbar .menu li.cb a {
    padding: 0 35px
  }
}

.navbar .menu li.cb a:hover {
  background: #004cb4
}

@media (min-width: 1200px) {
  .navbar .menu li.cb i {
    display: none
  }
}

@media (max-width: 1199px) {
  .navbar .menu li.cb span {
    display: none
  }
}

.navbar .menu li.active a {
  color: #bdbdbd
}

.navbar .menu a {
  color: #212121;
  text-decoration: none;
  -webkit-transition: color .3s;
  transition: color .3s
}

.navbar .menu a:hover {
  color: #bdbdbd
}

.poster {
  position: relative;
  overflow: hidden;
  .poster-text-wrapper {
    height: 400px;
    display: flex;
    align-items: center;
    padding: 100px 0 0;
    @media (min-width: 992px) {
      height: 460px;
    }
  }
  .poster-text {
    max-width: 820px;
    color: #fff;
    h1 {
      margin: 0 0 35px;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .poster-overlay {
    background: rgba(16, 18, 28, 0.35)
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-transition: 1s opacity;
    transition: 1s opacity
  }
}

.mobile-nav-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 198
}

.mobile-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 250px;
  padding: 30px 15px 30px 20px;
  color: #bdbdbd;
  background: #2b2f3c;
  transform: translate3d(250px, 0, 0);
  transition: transform .3s;
  overflow-y: auto;
  z-index: 199;
}

.mobile-nav.in {
  transform: translate3d(0, 0, 0)
}

.mobile-nav .logo {
  display: inline-block;
  margin-bottom: 40px;
  color: #fff;
  font-size: 34px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  -webkit-transition: color .3s;
  transition: color .3s
}

.mobile-nav .logo:hover {
  color: #bdbdbd
}

.mobile-nav .menu {
  margin-bottom: 40px;
  li {
    margin-bottom: 12px;
    font-size: 19px
  }
  a {
    display: block;
    color: #fff;
    text-decoration: none;
    -webkit-transition: color .3s;
    transition: color .3s;
    &:hover {
      color: #bdbdbd
    }
  }
  .sub {
    list-style: none;
    padding: 0 0 0 30px;
    margin: 8px 0 14px;
    li {
      font-size: 14px;
      a {
        color: #bdbdbd;
      }
    }
  }
}





.mobile-nav .contact-details li {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.35
}

body {
  &.main {

  }
}

.page-body {
  padding-top: 70px;
  background: url("../img/page_bg.jpg") repeat center 0;
  @media (min-width: 576px) {
    padding-top: 70px;
  }
  @media (min-width: 992px) {
    padding-top: 120px;
  }
}

.work-area {
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  @media (min-width: 1200px) {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.content-wrapper {
  padding: 20px;
  @media (min-width: 576px) {
    padding: 30px;
  }
}

.breadcrumb {
  margin-bottom: 0;
  color: #b2b7bf;
  font-size: 14px;
  line-height: 1.2;
  background: none;
  padding: 20px 20px 0 20px;
  @media (min-width: 576px) {
    padding: 30px 30px 0 30px;
  }
}

.breadcrumb a {
  color: #131313;
  text-decoration: none
}

.breadcrumb a:hover {
  text-decoration: underline
}

.advantages {
  ol {
    li {
      margin: 0 0 15px;
      font-size: 20px;
      color: #0057CD;
      font-family: "Casper", sans-serif;
      span {
        display: block;
        font-size: 18px;
        color: #000000;
        font-family: ClearSans, sans-serif;
      }
    }
  }
  blockquote {
    background: #F2F2F2;
    padding: 25px 30px;
    margin-left: -30px;
    margin-right: -30px;
  }
}

.operating {
  &-list {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0;
    flex-direction: column;
    margin: 0 0 40px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    .item {
      flex: 20% 0 0;
      text-align: center;
      position: relative;
      width: 160px;
      margin: 0 auto 15px;
      padding: 0 0 35px;
      @media (min-width: 768px) {
        padding: 0 20px 0 0;
        max-width: none;
        margin: 0;
        width: auto;
      }
      &:after {
        content: "";
        position: absolute;
        border: 10px solid;
        border-color: #0057CD transparent transparent transparent;
        bottom: 0;
        left: 50%;
        margin-left: -10px;
        @media (min-width: 768px) {
          top: 40%;
          border-color: transparent transparent transparent #0057CD;
          right: 0;
          left: auto;
          bottom: auto;
          margin-left: 0;
        }
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      .icon {
        height: 72px;
        margin: 0 0 5px;
      }
      .name {
        font-family: Casper, sans-serif;
        color: #0057CD;
        font-size: 20px;
      }
      .hint {
        font-size: 15px;
        line-height: 1.2;
        text-align: left;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        background: #FFFFFF;
        padding: 10px;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.1s;
        z-index: 2;
        top: 55%;
        @media (min-width: 768px) {
          top: 75%;
        }
      }
      &:hover {
        .hint {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.work-example {
  &-list {
    .item {
      margin: 0 0 30px;
      .picture {
        height: 150px;
        display: block;
      }
      img {
        max-width: 100%;
      }
    }
  }
}

.production {
  &-list {
    margin: 0 0 40px;
    .item {
      color: inherit;
      text-decoration: none;
      display: block;
      margin: 0 0 30px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s;
      &:hover {
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
      }
      .picture {
        height: 180px;
        img {
          max-width: 100%;
        }
      }
      .name {
        font-family: Casper, sans-serif;
        font-size: 20px;
        line-height: 1.1;
        padding: 10px 30px;
        min-height: 105px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.product-detail {
  margin: 0 auto 40px auto;
  max-width: 750px;
  img {
    max-width: 100%;
  }
  .picture {
    margin: 0 0 30px;
  }
  .product-detail-description {
    margin: 0 0 40px;
  }
  &-list {
    .head {
      font-family: Casper, sans-serif;
      margin: 0 0 20px;
    }
    .item {
      background: #F2F2F2;
      margin: 0 0 10px;
      padding: 20px;
      .image {
        margin: 0 0 20px;
        @media (min-width: 576px) {
          margin: 0;
        }
      }
      .name {
        font-weight: 600;
        margin: 0 0 15px;
      }
      .text {
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.equipment-list {
  .item {
    position: relative;
    padding: 50px 0 40px;
    font-size: 16px;
    border-bottom: 2px solid #e6e9ed;
    .picture {
      max-width: 370px;
      overflow: hidden;
      margin: 0 0 20px;
      @media (min-width: 576px) {
        margin: 0;
      }
      img {
        max-width: 100%;
      }
    }
    .title {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 1.2;
      font-family: 'Casper', sans-serif;
    }
    .text {
      font-family: 'ClearSans', Helvetica, Arial, sans-serif;
      color: rgb(0, 87, 205);
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.services-list {
  margin: 0 0 30px;
  .item-wrap {
    margin-bottom: 30px;
  }
  .item {
    position: relative;
    background: #fff;
    box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.15);
    transition: background .3s;
    height: 100%;
    .link {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    .picture {
      height: 240px;
      overflow: hidden;
    }
    .text {
      padding: 20px;
      @media (min-width: 992px) {
        padding: 40px;
      }
    }
    .title {
      max-height: 84px;
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 1.2;
      overflow: hidden;
      font-family: 'Casper', sans-serif;
      @media (min-width: 576px) {
        margin-bottom: 20px;
        font-size: 24px;
      }
    }
    .desc {
      font-size: 16px;
      line-height: 1.4;
      overflow: hidden;
    }
  }
}




.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 45px;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 0;
  background: #ffffff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #0057CD;
}

.swiper-button-next, .swiper-button-prev {
  color: #ffffff;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 22px;
  text-shadow: 0 0 12px rgba(0,0,0,0.6);
}

.services-detail {
  .swiper-slide {
    /* opacity: 0.6; */
    @media (min-width: 992px) {
      /* width: 66%; */
    }
    img {
      width: 100%;
    }
  }
  .swiper-slide-active {
    opacity: 1;
  }

  .swiper-button-prev {
    @media (min-width: 992px) {
      left: 20px;
    }
  }

  .swiper-button-next {
    @media (min-width: 992px) {
      right: 20px;
    }
  }

  .text {
    max-width: 750px;
    margin: 0 auto 40px auto;
  }
}

.text {
  ul {
    li {
      margin: 0 0 10px;
    }
  }
  a {
    color: inherit;
    font-weight: bold;
  }
}

.turning-stages {
  .item {
    display: flex;
    align-items: center;
    height: 150px;
    width: 100%;
    position: relative;
    box-shadow: 0 0 14px 7px rgba(0,0,0,0.19);
    padding: 20px 15px;
    max-width: 400px;
    margin: 0 auto 30px auto;
  }
  .left-arrow.item:after {
    content: '';
    border: 25px solid transparent;
    border-top: 29px solid rgb(0, 87, 205);
    position: absolute;
    top: calc(100%);
    transform: translateX(-50%);
    left: 50%;
  }
  .right-arrow.item:after {
    content: '';
    border: 25px solid transparent;
    border-top: 29px solid rgb(0, 87, 205);
    position: absolute;
    top: calc(100%);
    transform: translateX(-50%);
    left: 50%;
  }
  .bottom-arrow.item:after {
    content: '';
    border: 25px solid transparent;
    border-top: 29px solid rgb(0, 87, 205);
    position: absolute;
    top: calc(100%);
    transform: translateX(-50%);
    left: 50%;
  }
  .text {
    padding: 10px 20px;
    font-family: 'ClearSans', Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 20px;
    color: rgb(0, 87, 205);
    font-weight: 600;
    @media (min-width: 576px) {
      font-size: 18px;
    }
    @media (min-width: 992px) {
      font-size: 16px;
    }
    @media (min-width: 1200px) {
      font-size: 18px;
    }
  }
  .img-cont {
    width: 80px;
    @media (min-width: 992px) {
      width: 100px;
    }
  }
  .img-cont img{
    width: 80px;
    @media (min-width: 992px) {
      width: 100px;
    }
  }


  @media (min-width: 992px) {

    .left-arrow.item:after {
      content: '';
      border: 25px solid transparent;
      border-right: 29px solid #0057CD;
      position: absolute;
      left: -55px;
      top: 50%;
      transform: translateY(-50%);
    }
    .right-arrow.item:after {
      content: '';
      border: 25px solid transparent;
      border-left: 29px solid #0057CD;
      position: absolute;
      right: -55px;
      top: 50%;
      left: auto;
      transform: translateY(-50%);
    }
    .bottom-arrow.item:after {
      content: '';
      border: 25px solid transparent;
      border-top: 29px solid rgb(0, 87, 205);
      position: absolute;
      top: calc(100%);
      transform: translateX(-50%);
    }
    .item {
      max-width: none;
      margin: 0 0 30px 0;
      height: 170px;
    }
  }

}

















#modal-callback {
  max-width: 370px;
}

#modal-success {
  max-width: 570px;
}

.process .card {
  display: table;
  width: 100%;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 1.2;
  background-color: #fff;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.15)
}

@media (max-width: 991px) {
  .process .card {
    font-size: 28px
  }
}

.process .card.mod-1 {
  color: #fff;
  font-size: 44px;
  background-color: #0057cd;
  background-image: url("../img/icon_section_1.png");
  background-position: 10% 0
}

@media (max-width: 991px) {
  .process .card.mod-1 {
    font-size: 28px
  }
}

@media (max-width: 991px) {
  .process .card.mod-1 .card-text {
    background: rgba(0, 87, 205, 0.75)
  }
}

.process .card.mod-2 {
  background-image: url("../img/icon_section_2.png");
  background-position: 15% 50%
}

.process .card.mod-3 {
  background-image: url("../img/icon_section_3.png");
  background-position: 15% 50%
}

.process .card .card-text {
  display: table-cell;
  width: 100%;
  height: 210px;
  padding-left: 40%;
  vertical-align: middle
}

@media (max-width: 991px) {
  .process .card .card-text {
    padding: 0 15px;
    text-align: center;
    background: rgba(255, 255, 255, 0.75)
  }
}

.process .video {
  position: relative;
  height: 0;
  margin: 30px 0;
  padding-top: 25px;
  padding-bottom: 56.25%
}

.process .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

@media (max-width: 767px) {
  .fabric .case-heading {
    text-align: center
  }
}

.fabric .article {
  margin-bottom: 0;
}

.img-responsive {
  margin-bottom: 30px;
  max-width: 100%;
}

.article {
  margin-bottom: 80px;
}

@media (max-width: 991px) {
  .article {
    margin-bottom: 60px
  }
}

@media (max-width: 767px) {
  .article {
    margin-bottom: 40px
  }
}

.article p {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .article p {
    margin-bottom: 30px
  }
}

@media (max-width: 767px) {
  .article p {
    margin-bottom: 20px
  }
}

@media (max-width: 767px) {
  .features .case-heading {
    text-align: center
  }
}

@media (min-width: 1200px) {
  .features .case-body {
    padding: 40px 0
  }
}

@media (max-width: 480px) {
  .features .card {
    font-size: 22px !important;
  }
}

.features .card {
  margin-bottom: 30px;
  color: #fff;
  font-size: 16px;
  line-height: 1.2;
  background-color: #0057cd;
  background-repeat: no-repeat;
  background-position: 20px 140px
}

@media (max-width: 767px) {
  .features .card {
    max-width: 300px;
    margin: 0 auto 30px
  }
}

.features .card.mod-1 {
  color: #0057cd;
  font-size: 18px;
  background-color: #fff;
  background-image: url("../img/icon_4i.png");
  -webkit-box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.15)
}

@media (min-width: 1200px) {
  .features .card.mod-1 {
    margin: -40px 0;
    background-position: 30px 180px
  }
}

@media (max-width: 767px) {
  .features .card.mod-1 {
    background-position: 25px 150px
  }
}

@media (min-width: 1200px) {
  .features .card.mod-1 .card-text {
    height: 300px;
    padding: 80px 30px
  }
}

@media (max-width: 767px) {
  .features .card.mod-1 .card-text {
    height: 200px
  }
}

.features .card.mod-1 .title {
  margin-bottom: 22px;
  font-size: 22px
}

.features .card.mod-2 {
  background-image: url("../img/icon_2.png")
}

.features .card.mod-3 {
  background-image: url("../img/icon_3.png")
}

.features .card.mod-4 {
  background-image: url("../img/icon_1i.png")
}

.features .card.mod-5 {
  background-image: url("../img/icon_5.png")
}

.features .card .card-text {
  width: 100%;
  height: 200px;
  padding: 40px 20px;
  vertical-align: middle
}

@media (max-width: 767px) {
  .examples .case-heading {
    text-align: center
  }
}

.examples .card {
  margin-bottom: 45px;
  border: 0 none;
  border-radius: 0;
}

@media (max-width: 767px) {
  .examples .card {
    max-width: 300px;
    margin: 0 auto 45px
  }
}

.examples .card .card-figure {
  height: 180px;
  margin-bottom: 15px;
  overflow: hidden
}

.examples .card .card-text {
  height: 28px;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  overflow: hidden
}

@media (max-width: 767px) {
  .contact .case-heading {
    text-align: center
  }
}

.contact .btn-wrap { /*padding-top:20px;*/
  margin-bottom: 40px
}

@media (max-width: 767px) {
  .contact .btn-wrap {
    text-align: center
  }
}

.contact .contact-wrapper {
  padding-top: 10px
}

@media (max-width: 991px) {
  .contact .contact-wrapper {
    padding: 30px;
    background: #f2f2f2
  }
}

@media (max-width: 767px) {
  .contact .contact-details {
    text-align: center
  }
}

.contact .contact-details li {
  margin-bottom: 8px
}

@media (max-width: 767px) {
  .services .case-heading {
    text-align: center
  }
}

.services .card {
  position: relative;
  margin-bottom: 30px;
  background: #fff;
  -webkit-box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: background .3s;
  transition: background .3s
}

@media (max-width: 767px) {
  .services .card {
    max-width: 370px;
    margin: 0 auto 30px
  }
}

.services .card:hover {
  background: #f8f8f8
}

.services .card .card-figure {
  height: 240px;
  overflow: hidden
}

.services .card .card-text {
  height: 352px;
  padding: 40px
}

{
.services .card .card-text {
  padding: 30px
}

}
.services .card .title {
  max-height: 84px;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 1.2;
  overflow: hidden
}

.services .card .desc {
  max-height: 168px;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden
}

@media (max-width: 767px) {
  .reviews .case-heading {
    text-align: center
  }
}

.reviews .case-body {
  border-top: 2px solid #e6e9ed
}

.reviews .card {
  position: relative;
  padding: 50px 0 40px;
  font-size: 16px;
  border-bottom: 2px solid #e6e9ed
}

@media (max-width: 991px) {
  .reviews .card {
    padding: 30px 0 20px
  }
}

.reviews .card .figure {
  max-width: 260px;
  overflow: hidden
}

@media (max-width: 991px) {
  .reviews .card .figure {
    margin: 0 auto 30px
  }
}

.reviews .card .figure img {
  max-width: 100%
}

.reviews .card .title {
  padding-top: 8px;
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 1.2
}

@media (max-width: 991px) {
  .reviews .card .title {
    text-align: center
  }
}

.reviews .card .desc {
  margin-bottom: 30px;
  color: #757575;
  line-height: 1.2
}

@media (max-width: 991px) {
  .reviews .card .desc {
    text-align: center
  }
}

.reviews .card .text {
  line-height: 1.5
}

.reviews .card .text p {
  margin: 0 0 20px
}

@media (max-width: 767px) {
  .equip .case-heading {
    text-align: center
  }
}

.equip .case-body {
  border-top: 2px solid #e6e9ed
}

.equip .card {
  position: relative;
  padding: 50px 0 40px;
  font-size: 16px;
  border-bottom: 2px solid #e6e9ed
}

@media (max-width: 991px) {
  .equip .card {
    padding: 30px 0 20px
  }
}

.equip .card .figure {
  max-width: 370px;
  overflow: hidden
}

@media (max-width: 991px) {
  .equip .card .figure {
    margin: 0 auto 20px
  }
}

.equip .card .figure img {
  max-width: 100%
}

.equip .card .title {
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 1.2
}

.equip .card .text p {
  margin: 0 0 20px;
  list-style: 1.5
}

.equip .card .text ul {
  line-height: 2
}

.equip .card .text li {
  position: relative;
  padding-left: 22px;
  margin-bottom: 10px
}

.equip .card .text li:before {
  content: '— ';
  position: absolute;
  top: 0;
  left: 0
}

.mobile-show, .mobile-hidden {
  display: none
}

@media (max-width: 767px) {
  .mobile-show {
    display: block
  }
}

@media (min-width: 768px) {
  .mobile-hidden {
    display: block;
  }
}